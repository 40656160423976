import { createGlobalStyle, css } from 'styled-components';

const FormStyle = createGlobalStyle`${css`
	.form-container {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		user-select: none;

		.form-rows {
			width: 100%;

			.form-row {
				&:last-child {
					.form-col {
						padding-bottom: 0;
					}
				}
			}

			.textarea-cont {
				--textAreaSize: var(--sp16x);

				position: relative;
				margin-bottom: var(--sp2x);
				border-bottom: 2px solid var(--color2);

				textarea {
					width: 100%;
					min-height: var(--textAreaSize);
					border: 0;
					background-color: transparent;
					color: var(--color2);
					font-size: var(--h6);
					resize: none;
					line-height: var(--lineHeightForm);

					&:focus {
						outline: 0;
					}
				}
			}

			.checkbox-cont {
				&.BigCheckbox {
					--checkMarg: var(--sp2x);
					--borderRad: 35px;

					margin-right: calc((var(--checkMarg) / 2) * -1);
					margin-left: calc((var(--checkMarg) / 2) * -1);

					.tag-checkbox {
						opacity: 0;
						overflow: hidden;
						position: relative;
						border-radius: var(--borderRad);
						// background-color: var(--color1);
						padding: 8px 20px;
						display: inline-block;
						margin-bottom: var(--checkMarg);
						margin-right: calc(var(--checkMarg) / 2);
						margin-left: calc(var(--checkMarg) / 2);

						.tag-checkbox-name {
							font-weight: var(--ffMedium);
							font-family: var(--fontFamily2);
							font-size: var(--formSize);
							color: var(--color2);
							// mix-blend-mode: difference;
						}

						input {
							cursor: pointer;
							opacity: 0;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							z-index: 11;
							line-height: var(--lineHeightForm);
						}

						// svg{
						//     position: absolute;
						//     top: 0;
						//     left: 0;
						//     right: 0;
						//     bottom: 0;
						//     fill:var(--color1);
						//     mix-blend-mode: difference;
						// }

						&.checked {
							background-color: var(--color2);

							.tag-checkbox-name {
								font-style: var(--ffItalic);
								color: var(--color1);
							}
						}

						&:after {
							content: '';
							position: absolute;
							top: 0px;
							left: 0px;
							right: 0px;
							bottom: 0px;
							border: 3px solid var(--color2);
							border-radius: var(--borderRad);
						}
					}
				}

				&.smallCheckbox {
					--checkboxIconSize: 20px;

					display: flex;
					flex-wrap: wrap;

					.def-checkbox {
						margin-bottom: var(--checkboxBottom);
						flex: 0 0 100%;
						max-width: 100%;
						display: flex;
						align-items: center;

						&.checked {
							.def-checkbox-el {
								background-color: var(--color1);
							}
						}

						.def-checkbox-el {
							position: relative;
							border: 2px solid var(--color1);
							border-radius: 4px;
							width: var(--checkboxIconSize);
							height: var(--checkboxIconSize);
							margin-right: var(--sp2x);

							input {
								opacity: 0;
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								line-height: var(--lineHeightForm);
							}
						}

						.comp_title {
							font-weight: var(--ffMedium);
							font-size: var(--p);
							font-family: var(--fontFamily2);
						}
					}
				}
			}
		}

		.btn-actions {
			position: relative;
			width: 100%;
		}

		&.form-error {
			position: relative;
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.form-rows {
				.checkbox-cont {
					&.BigCheckbox {
						--borderRad: 100px;

						display: flex;
						flex-wrap: wrap;
					}

					&.smallCheckbox {
						--checkboxIconSize: 24px;
					}
				}

				.textarea-cont {
					--textAreaSize: var(--sp25x);
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.form-rows {
				.checkbox-cont {
					&.smallCheckbox {
						.def-checkbox {
							flex: 0 0 50%;
							max-width: 50%;
						}
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.form-rows {
				.checkbox-cont {
					&.BigCheckbox {
						--checkMarg: var(--sp5x);

						.tag-checkbox {
							padding: 10px 40px;
						}
					}
				}

				.textarea-cont {
					--textAreaSize: var(--sp28x);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.form-rows {
				.checkbox-cont {
					&.BigCheckbox {
						--checkMarg: var(--sp5x);

						.tag-checkbox {
							padding: 12px 50px;
						}
					}

					&.smallCheckbox {
						--checkboxIconSize: 26px;
					}
				}

				.textarea-cont {
					--textAreaSize: var(--sp36x);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			.form-rows {
				.checkbox-cont {
					&.BigCheckbox {
						--checkMarg: var(--sp5x);

						.tag-checkbox {
							padding: 14px 55px;
						}
					}

					&.smallCheckbox {
						--checkboxIconSize: 28px;
					}
				}

				.textarea-cont {
					--textAreaSize: var(--sp44x);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			.form-rows {
				// padding-bottom: var(--sp2x);
				.checkbox-cont {
					&.BigCheckbox {
						--checkMarg: var(--sp8x);

						.tag-checkbox {
							padding: 14px 64px;
						}
					}

					&.smallCheckbox {
						--checkboxIconSize: 36px;
					}
				}

				.textarea-cont {
					--textAreaSize: var(--sp52x);
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			.form-rows {
				// padding-bottom: var(--sp2-5x);
				.checkbox-cont {
					&.BigCheckbox {
						--checkMarg: var(--sp10x);

						.tag-checkbox {
							padding: 18px 84px;
						}
					}

					&.smallCheckbox {
						--checkboxIconSize: 36px;
					}
				}

				.textarea-cont {
					--textAreaSize: var(--sp54x);
				}
			}
		}
	}
`}`;

export default FormStyle;
