'use client';

import { createContext, useState, useEffect, useCallback } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { ThemeProvider } from 'styled-components';

//* Styles
import themes from '@styles/theme';
import HelperClass from '@styles/helperClass';
import Variables from '@styles/variables';
import Typography from '@styles/typography';
import FormStyle from '@/styles/formStyle';

const UIContext = createContext(null);

//* Global Variables
const errorsInitialState = {
	formError: {
		error: false,
		msg: '',
	},
	systemError: {
		error: false,
		msg: '',
	},
};

const UIProvider = (props) => {
	const router = useRouter();
	const pathname = usePathname();

	//! States
	const [state, setState] = useState({
		popupIsOpen: false,
		highComponent: null,
		popupComponent: null,
		loading: false,
		queryObj: '',
		preloader: true,
		winWidth: 1920,
		winHeight: 1080,
		screenSizes: {
			screen3XL: 2560,
			screen2XL: 1920,
			screenXL: 1440,
			screenL: 1366,
			screenM: 1280,
			screenS: 1024,
			screenXS: 768,
		},
		colors: {},
		darkMode: false,
		lightHeader: false,
		isMobile: props.isMobile,
		isApple: props.isApple,
		globalData: props.globalData,
		...errorsInitialState,
	});

	//! Screen Resize
	const screenResize = useCallback(() => {
		setState((prevState) => ({
			...prevState,
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		}));
	}, []);

	//! Get Media Screen Sizes from Css Variables
	const getScreenSizes = useCallback(() => {
		const root = getComputedStyle(document.documentElement);

		const screenSizes = {
			screen3XL: parseInt(root.getPropertyValue('--desktopSizeXL')),
			screen2XL: parseInt(root.getPropertyValue('--desktopSizeL')),
			screenXL: parseInt(root.getPropertyValue('--desktopSizeM')),
			screenL: parseInt(root.getPropertyValue('--desktopSizeS')),
			screenM: parseInt(root.getPropertyValue('--desktopSizeXS')),
			screenS: parseInt(root.getPropertyValue('--tabletSize')),
			screenXS: parseInt(root.getPropertyValue('--tabletSizeS')),
		};

		const colors = {
			black: root.getPropertyValue('--black').trim(),
			white: root.getPropertyValue('--white').trim(),
			color1: root.getPropertyValue('--color1').trim(),
			color2: root.getPropertyValue('--color2').trim(),
		};

		setState((prevState) => ({
			...prevState,
			screenSizes,
			colors,
		}));
	}, []);

	const disablePreloader = () => {
		setState((prevState) => ({
			...prevState,
			preloader: false,
		}));
	};

	//! Toggle Light/Dark Header
	const toggleLightHeader = useCallback((light = false) => {
		setState((prevState) => ({
			...prevState,
			lightHeader: light,
		}));
	}, []);

	//! Check Not Found Page
	const checkNotFoundPage = useCallback(() => {
		if (pathname === '/404') {
			disablePreloader();
		}
	}, [pathname]);

	//! Add Error Message
	const addErrorMsg = useCallback((type, msg) => {
		type === 'form'
			? setState((prevState) => ({
					...prevState,
					formError: {
						error: true,
						msg,
					},
			  }))
			: setState((prevState) => ({
					...prevState,
					systemError: {
						error: true,
						msg,
					},
			  }));
	}, []);

	//! Remove Error Message
	const removeErrorMsg = useCallback((type) => {
		type === 'form'
			? setState((prevState) => ({
					...prevState,
					formError: {
						error: false,
						msg: '',
					},
			  }))
			: setState((prevState) => ({
					...prevState,
					systemError: {
						error: false,
						msg: '',
					},
			  }));
	}, []);

	//! Add High Component
	const addHighComponent = useCallback((component = null) => {
		setState((prevState) => ({
			...prevState,
			highComponent: component,
		}));
	}, []);

	//! Remove High Component
	const removeHighComponent = useCallback(() => {
		setState((prevState) => ({
			...prevState,
			highComponent: null,
		}));
	}, []);

	//! Scroll To Top
	const scrollToTop = useCallback((behavior = 'instant') => {
		window.scrollTo({
			top: 0,
			behavior,
		});
	}, []);

	//! Disable DOM Scroll
	const disableDomScroll = useCallback(() => {
		//? document.querySelector('html').classList.add('hide-scroll')

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

		//! if any scroll is attempted, set this to the previous value
		window.onscroll = function (e) {
			e.preventDefault();
			window.scrollTo(scrollLeft, scrollTop);
		};
	}, []);

	//! Enable DOM Scroll
	const enableDomScroll = useCallback(() => {
		//? document.querySelector('html').classList.remove('hide-scroll');

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

		window.onscroll = function () {};
		window.scrollTo(scrollLeft, scrollTop);
	}, []);

	//! Toggle Dark Mode
	const toggleDarkMode = useCallback(() => {
		let root = document.documentElement;

		const color1 = state.colors.color1 === state.colors.black ? state.colors.white : state.colors.black,
			color2 = state.colors.color2 === state.colors.black ? state.colors.white : state.colors.black;

		root.style.setProperty('--color1', color1);
		root.style.setProperty('--color2', color2);

		setState((prevState) => ({
			...prevState,
			darkMode: !state.darkMode,
			colors: { ...state.colors, color1, color2 },
		}));

		return true;
	}, [state]);

	//! Component Did Mount
	useEffect(() => {
		checkNotFoundPage();
		getScreenSizes();
		screenResize();
	}, []);

	//! Component Did Update
	// useEffect(() => {
	// 	checkNotFoundPage();
	// 	// TODO: Portfolio & Team toggle light header
	// 	if (!props.router.route.includes('/portfolio/[slug]') && !props.router.route.includes('/team') && state.lightHeader) {
	// 		toggleLightHeader();
	// 	}
	// }, []);

	//! Component Will Unmount
	useEffect(() => {
		window.addEventListener('resize', screenResize);

		return () => {
			window.removeEventListener('resize', screenResize);
		};
	}, [screenResize]);

	//! Methods
	const methods = {
		addHighComponent,
		removeHighComponent,
		toggleLightHeader,
		addErrorMsg,
		removeErrorMsg,
		disableDomScroll,
		enableDomScroll,
		toggleDarkMode,
		scrollToTop,
		disablePreloader,
	};

	return (
		<UIContext.Provider
			value={{
				...state,
				...methods,
			}}>
			<ThemeProvider theme={themes}>
				<Variables />
				<Typography />
				<HelperClass />
				<FormStyle />
				{props.children}
			</ThemeProvider>
		</UIContext.Provider>
	);
};

export default UIProvider;
export const UIConsumer = UIContext.Consumer;
