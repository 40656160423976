import styled from 'styled-components';

const SocIconStyle = styled.div`
	&.soc_icon_wrap1 {
		.soc_icon_item {
			display: block;
			position: relative;
			width: var(--sp5x);
			height: var(--sp5x);

			.soc_icon {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;

				&:before {
					font-size: 18px;
				}
			}

			svg {
				fill: var(--color2);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
			display: flex;
			justify-content: space-between;
			margin-top: var(--sp8x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
			.soc_icon_item {
				width: var(--sp6x);
				height: var(--sp6x);

				.soc_icon {
					&:before {
						font-size: 20px;
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			position: absolute;
			top: 0;
			right: var(--containerPaddingLR);

			.soc_icon_item {
				margin-bottom: var(--sp2x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.soc_icon_item {
				width: var(--sp6x);
				height: var(--sp6x);

				.soc_icon {
					&:before {
						font-size: 20px;
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.soc_icon_item {
				width: var(--sp7x);
				height: var(--sp7x);

				.soc_icon {
					&:before {
						font-size: 22px;
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.soc_icon_item {
				width: var(--sp7x);
				height: var(--sp7x);

				.soc_icon {
					&:before {
						font-size: 24px;
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			.soc_icon_item {
				width: var(--sp8x);
				height: var(--sp8x);
				margin-bottom: var(--sp2-5x);

				.soc_icon {
					&:before {
						font-size: 26px;
					}
				}
			}
		}
	}

	&.soc_icon_wrap2 {
		display: flex;

		.soc_icon_item {
			margin-right: var(--sp3x);
			width: var(--sp8x);
			height: var(--sp4x);
			display: flex;
			align-items: center;
			justify-content: center;

			.soc_icon_border {
				position: relative;
				overflow: hidden;
				border: 3px solid;
				border-color: var(--color2);
				border-radius: 90%;
				height: 100%;
				width: 100%;
			}

			&:last-child {
				margin-right: 0;
			}

			.soc_icon {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;

				&:before {
					font-size: 18px;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		&.soc_icon_wrap2 {
			.soc_icon_item {
				width: var(--sp8x);
				height: var(--sp4x);
				margin-right: var(--sp4x);

				.soc_icon {
					&:before {
						font-size: 18px;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		&.soc_icon_wrap2 {
			.soc_icon_item {
				width: var(--sp8x);
				height: var(--sp4x);
				margin-right: var(--sp4x);

				.soc_icon {
					&:before {
						font-size: 18px;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		&.soc_icon_wrap2 {
			.soc_icon_item {
				width: var(--sp8x);
				height: var(--sp4x);
				margin-right: var(--sp4x);

				.soc_icon {
					&:before {
						font-size: 18px;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		&.soc_icon_wrap2 {
			.soc_icon_item {
				width: var(--sp8x);
				height: var(--sp4x);
				margin-right: var(--sp4x);

				.soc_icon {
					&:before {
						font-size: 18px;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		&.soc_icon_wrap2 {
			.soc_icon_item {
				width: var(--sp8x);
				height: var(--sp4x);
				margin-right: var(--sp4x);

				.soc_icon {
					&:before {
						font-size: 18px;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		&.soc_icon_wrap2 {
			.soc_icon_item {
				width: var(--sp8x);
				height: var(--sp4x);
				margin-right: var(--sp4x);

				.soc_icon {
					&:before {
						font-size: 18px;
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
		&.soc_icon_wrap2 {
			.soc_icon_item {
				width: var(--sp9x);
				height: var(--sp4x);
				margin-right: var(--sp4x);

				.soc_icon {
					&:before {
						font-size: 20px;
					}
				}
			}
		}
	}
`;

export default SocIconStyle;
