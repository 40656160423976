import { forwardRef } from 'react';

//* Providers
import { UIConsumer } from '@context/providers/UIProvider';

//* Helpers
import getContext from '@/lib/get-context';

export default function withUIContext(ConnectedComponent, properties = []) {
    return forwardRef((props, ref) => {
        return (
            <UIConsumer>
                {(context) => {
                    let contextProps = properties.length ? getContext(context, properties) : {};

                    return (
                        <ConnectedComponent
                            ref={ref}
                            {...props}
                            {...contextProps}
                        />
                    );
                }}
            </UIConsumer>
        );
    });
}
