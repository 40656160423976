import { createGlobalStyle, css } from 'styled-components';

const HelperClass = createGlobalStyle`${css`
	//! Fonts
	.font-anonymous {
		font-family: var(--fontFamily1);
	}

	.font-poppins {
		font-family: var(--fontFamily2);
	}

	.font-neueMetana-regular {
		font-family: var(--fontFamily3);
		font-weight: var(--ffRegular);
	}

	.font-italic {
		font-style: var(--ffItalic);
	}

	.font-light {
		font-weight: var(--ffLight);
	}

	.font-regular {
		font-weight: var(--ffRegular);
	}

	.font-medium {
		font-weight: var(--ffMedium);
	}

	.font-semi-bold {
		font-weight: var(--ffSemiBold);
	}

	.font-bold {
		font-weight: var(--ffBold);
	}

	//! Text decoration
	.font-underline {
		text-decoration: underline;
	}

	//! Text transform
	.text_upper {
		text-transform: uppercase;
	}

	//! Flex
	.flexWrap {
		display: flex;
	}

	.h1,
	.h2 {
		.item-2 {
			font-weight: var(--ffLight);
			font-style: normal;
		}
	}
`}`;

export default HelperClass;
