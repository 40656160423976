import { createGlobalStyle, css } from 'styled-components';

const Typography = createGlobalStyle`${css`
	* {
		box-sizing: border-box;
		outline: none;
		margin: 0;
		padding: 0;
		-webkit-appearance: none;
		-webkit-text-size-adjust: 100%;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; //! Touch highlight color

		::selection {
			background-color: #ffdd00 !important;
		}

		::-moz-selection {
			background-color: #ffdd00 !important;
		}
	}

	html {
		min-height: 100vh;
		position: relative;
		scroll-behavior: smooth;
		background-color: var(--color1);

		&.hide-scroll {
			&,
			body {
				width: 100%;
				height: 100%;
				overflow: hidden;
			}
		}

		body {
			font-family: var(--fontFamily1);
			font-weight: var(--ffRegular);
			color: var(--color2);
			overflow-x: hidden;
			background-color: var(--color1);

			&.phone {
				* {
					pointer-events: none !important;
				}
			}
		}

		li {
			display: flex;

			p {
				margin-bottom: 0 !important;
			}
		}
	}

	.modal-open {
		overflow-y: hidden;
	}

	.ov-hidden {
		overflow: hidden;
	}

	.container {
		width: 100%;
		margin: 0 auto;
		padding-left: var(--containerPaddingLR);
		padding-right: var(--containerPaddingLR);
	}

	.section-item {
		margin-bottom: var(--sectionToSection);
	}

	main {
		padding: 0.1px 0;
		min-height: 100vh;
		position: relative;
		z-index: 3;
		background-color: var(--color1);

		&.mainBack {
			background: var(--color2);

			& ~ footer {
				z-index: 2;
			}
		}

		&.contactForm {
			background-color: var(--color1);
			padding-top: calc(var(--headerHeight) + var(--sp5x)) !important;

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
				position: relative;
			}

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
				padding-left: var(--sp4x);
				padding-right: var(--sp9x);
			}

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
				padding-top: calc(var(--headerHeight) + var(--sp7x)) !important;
				padding-left: var(--sp8x);
				padding-right: var(--sp8x);
			}

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
				padding-top: calc(var(--headerHeight) + var(--sp12x)) !important;
				padding-left: var(--sp9x);
				padding-right: var(--sp9x);
			}

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
				padding-top: calc(var(--headerHeight) + var(--sp10x)) !important;
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
				padding-top: calc(var(--headerHeight) + var(--sp7x)) !important;
			}
		}
	}

	a {
		display: inline-block;
		text-decoration: none;
		color: inherit;

		&:focus,
		&:focus-within,
		&:focus-visible,
		&:visited,
		&:hover {
			outline: none;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		}
	}

	img {
		width: 100% !important;
	}

	ul {
		list-style: none;
	}

	.link {
		text-decoration: underline;
		font-style: var(--ffItalic);
		color: grey;
	}

	.line-txt:not(:first-child) {
		margin-top: var(--sp1x);
	}

	//! Font sizes
	h1,
	.h1 {
		font-size: var(--h1);
		line-height: var(--lineHeightH1);
	}

	h2,
	.h2 {
		font-size: var(--h2);
		line-height: var(--lineHeightH2);
	}

	h3,
	.h3 {
		font-size: var(--h3);
		line-height: var(--lineHeightH3);
	}

	h4,
	.h4 {
		font-size: var(--h4);
		line-height: var(--lineHeightH4);
	}

	h5,
	.h5 {
		font-size: var(--h5);
		line-height: var(--lineHeightH5);
	}

	h6,
	.h6 {
		font-size: var(--h6);
		line-height: var(--lineHeightH6);
	}

	.p-l {
		font-size: var(--pL);
		line-height: var(--lineHeightP) !important;
	}

	.p-m {
		font-size: var(--pM);
		line-height: var(--lineHeightP) !important;
	}

	p,
	.p {
		font-size: var(--p);
		line-height: var(--lineHeightP) !important;
	}

	//! Input line styles
	.input-line-svg-styles {
		fill: none;
		position: absolute;
		z-index: 11;
		pointer-events: none;
		bottom: -4px;
		left: 0;
		right: 0;
		transform: translateX(-66.6%);
	}

	//! Peezzy Eyes Styles
	.peezzyEyes {
		position: absolute;
		top: 0;
		left: 50%;
		width: 80%;
		transform: translate3d(-50%, 0, 0);

		.defaultStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3px;
		}

		.fillWhite {
			fill: var(--color1);
		}

		.fill {
			fill: var(--color2);
		}
	}

	//! Peezzy Jump Styles
	.peezzyJump {
		.defaultStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3.5px;
		}

		.fill {
			fill: var(--color2);
		}
	}

	//! Rolly Styles
	.rolly {
		width: 200px;
		align-self: flex-end;
		overflow: visible;

		.fillBalck {
			fill: var(--color2);
		}

		.st1 {
			fill: var(--color1);
		}

		.st2 {
			fill: none;
			stroke: var(--color2);
			stroke-width: 3;
			stroke-linecap: round;
			stroke-miterlimit: 10;
		}

		.st3 {
			fill: none;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			width: 130px;
		}
	}

	//! Rolly Eyes Styles
	.rollyEyes {
		position: absolute;
		top: 0;
		left: 50%;
		width: 50%;
		transform: translate3d(-50%, 0, 0);

		.defaultStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3px;
		}

		.fillWhite {
			fill: var(--color1);
		}

		.fill {
			fill: var(--color2);
		}
	}

	//! Rolly Jump Styles
	.rollyJump {
		.defaultStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3.5px;
		}

		.fill {
			fill: var(--color2);
		}
	}

	//! Sharpy Styles
	.sharpy {
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 0;

		.fillWhite {
			fill: var(--color1);
		}

		.hStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3px;
		}

		.fStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3px;
		}

		.shaHand {
			fill: var(--color2);
			stroke-width: 20;
		}

		.fillBlack {
			fill: var(--color2);
		}

		.hideOpacity {
			opacity: 0;
		}

		.hidden {
			visibility: hidden;
		}
	}

	//! Sharpy Eyes Styles
	.sharpyEyes {
		position: absolute;
		top: 0;
		left: 50%;
		width: 60%;
		transform: translate3d(-50%, 0, 0);

		.defaultStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3px;
		}

		.fillWhite {
			fill: var(--color1);
		}

		.fill {
			fill: var(--color2);
		}
	}

	//! Sharpy Jump Styles
	.sharpyJump {
		.defaultStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3.5px;
		}

		.fill {
			fill: var(--color2);
		}
	}

	//! Smile Styles
	.smile {
		.st0 {
			fill: none;
			stroke: var(--color2);
			stroke-width: 2;
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.st1 {
			fill: none;
			stroke: none;
		}
	}

	//! Stroke Svg Styles
	.stroke-svg-styles {
		fill: none !important;
		overflow: visible;

		path {
			overflow: visible;
			stroke: var(--color2);
			stroke-width: 10px;
			stroke-linejoin: round;
			stroke-dasharray: 2600 2600;
			stroke-dashoffset: 2600;
		}
	}

	//! Ziggy Eyes Styles
	.ziggyEyes {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		.defaultStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-miterlimit: 10;
			stroke-width: 2.83464574813843px;
		}

		.fillWhite {
			fill: var(--color1);
		}

		.fill {
			fill: var(--color2);
		}
	}

	//! Ziggy Jump Styles
	.ziggyJump {
		.defaultStyle {
			fill: none;
			stroke: var(--color2);
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 3px;
		}

		.fill {
			fill: var(--color2);
		}
	}

	//! Dark Mode Switcher Styles
	.colorSwitcher {
		--colorSwitcher: var(--sp4x);

		height: var(--colorSwitcher);
		fill: var(--color2);

		.circle {
			fill: var(--color1);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			--colorSwitcher: var(--sp5x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			--colorSwitcher: var(--sp6x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			--colorSwitcher: var(--sp7x);
		}
	}

	//! Button Other Link Styles
	.otherLinkWrap {
		display: inline-block;

		.otherLink {
			border: 2px solid var(--color2);
			padding: var(--sp2-5x) var(--sp3x);
			border-radius: 100%;
			letter-spacing: 1.5px;
			text-transform: uppercase;

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
				padding: var(--sp3x) var(--sp4x);
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
				padding: var(--sp3x) var(--sp5x);
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
				padding: var(--sp3x) var(--sp5x);
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
				padding: var(--sp4x) var(--sp8x);
			}
		}
	}

	//! Awards Black Styles
	.awardsItemColumn-black {
		display: block;
		position: relative;
		padding-bottom: 64%;
		margin-bottom: var(--sp5x);
		transform-origin: 50% 50%;
		transform: scale(0);
		overflow: hidden;

		.border {
			pointer-events: none;
			border-style: solid;
			border-color: var(--color1);
			overflow: hidden;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: 1;
		}

		svg {
			fill: var(--color1);
		}

		&:nth-child(odd) {
			.awardsItemCont {
				align-items: flex-end;
			}
		}

		&:nth-child(even) {
			.awardsItemCont {
				justify-content: flex-end;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:nth-child(1) {
			.awardsItemCont {
				.awardsItemIcon {
					> svg {
						top: -10%;
						left: -26%;
						height: 130%;
						width: 110%;
					}
				}
			}
		}

		&:nth-child(2) {
			.awardsItemCont {
				.awardsItemIcon {
					> svg {
						top: -25%;
						right: -8%;
						width: 90%;
						height: 146%;
					}
				}
			}
		}

		&:nth-child(3) {
			.awardsItemCont {
				.awardsItemIcon {
					> svg {
						top: -8%;
						left: -6%;
						width: 100%;
						height: 136%;
					}
				}
			}
		}

		&:nth-child(4) {
			.awardsItemCont {
				.awardsItemIcon {
					> svg {
						transform: rotate(-13deg);
						top: -7%;
						right: -15%;
						width: 110%;
						height: 125%;
					}
				}
			}
		}

		.awardsItemCont {
			pointer-events: none;
			overflow: hidden;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			padding: var(--sp2-5x);
			display: flex;
			transform: scale(2.1);
			background-color: var(--color2);

			.awardsItemInfo {
				max-width: 90%;
				mix-blend-mode: difference;

				.awardName {
					margin-bottom: var(--sp2x);
				}

				.awardsItemDescription {
					margin-bottom: var(--sp1x);
				}

				a {
					pointer-events: painted;
				}
			}

			.awardsItemIcon {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;

				> svg {
					pointer-events: painted;
					position: absolute;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			&:nth-child(3) {
				.awardsItemCont {
					align-items: flex-start;
				}
			}

			&:nth-child(4) {
				.awardsItemCont {
					justify-content: flex-end;
					align-items: flex-end;
				}
			}

			&:nth-last-child(-n + 2) {
				margin-bottom: 0;
			}

			.awardsItemCont {
				padding: var(--sp2-5x) var(--sp4x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			margin-right: var(--sp2x);
			margin-left: var(--sp2x);
			max-width: calc(50% - var(--sp4x));

			&:nth-last-child(-n + 2) {
				margin-bottom: 0;
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			padding-bottom: 31%;
			flex: 0 0 50%;

			&:nth-child(4) {
				.awardsItemCont {
					justify-content: flex-end;
					align-items: flex-end;
				}
			}

			&:nth-child(3) {
				.awardsItemCont {
					align-items: flex-start;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.awardsItemCont {
				padding: var(--sp4x) var(--sp4x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.awardsItemCont {
				padding: var(--sp5x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			.awardsItemCont {
				padding: var(--sp5x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			.awardsItemCont {
				padding: var(--sp5x);
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			&:nth-last-child(-n + 2) {
				margin-bottom: 0;
			}

			.awardsItemCont {
				padding: var(--sp8x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			margin-right: var(--sp4x);
			margin-left: var(--sp4x);
			margin-bottom: var(--sp8x);
			max-width: calc(50% - var(--sp8x));

			.awardsItemCont {
				padding: var(--sp8x);
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			margin-right: var(--sp6x);
			margin-left: var(--sp6x);
			margin-bottom: var(--sp12x);
			max-width: calc(50% - var(--sp12x));
			padding-bottom: 27.4%;
		}
	}

	//! Awards White Styles
	.awardsItemColumn-white {
		opacity: 0;
		padding-top: var(--sp2-5x);
		padding-bottom: var(--sp2-5x);
		border-bottom: 2px solid var(--color2);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&:first-child {
			border-top: 2px solid var(--color2);
		}

		.awardsItemCont {
			flex: 1 1 auto;
			max-width: 65%;

			.awardName {
				margin-bottom: var(--sp2x);
			}

			> div > p {
				margin-bottom: var(--sp1x);
			}
		}

		.awardsItemWrapIcon {
			width: var(--sp11x);
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			svg {
				overflow: visible;
				width: var(--sp8x);
				stroke: var(--color2);
				stroke-width: 2.5px;

				path:last-child {
					stroke-linecap: round;
					stroke-linejoin: round;
					stroke-dasharray: 400;
					stroke-dashoffset: 468;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			padding-top: var(--sp3x);
			padding-bottom: var(--sp3x);

			.awardsItemCont {
				.awardsItemInfo1 {
					flex-direction: column;
				}

				.awardsItemInfo2 {
					flex-direction: column-reverse;
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.awardsItemCont {
				display: flex;
				flex-wrap: wrap;
				max-width: 100%;

				.awardsItemInfo1 {
					width: 57%;
				}

				.awardsItemInfo1,
				.awardsItemInfo2 {
					display: flex;
					justify-content: space-between;
				}

				.awardName {
					margin-bottom: var(--sp1x);
				}

				> div > p {
					margin-bottom: 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.awardsItemWrapIcon {
				align-items: center;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			padding-top: var(--sp5x);
			padding-bottom: var(--sp5x);
			--tab-left: var(--sp3x);
			--tab-right: var(--sp3x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.awardsItemCont {
				.awardsItemInfo1,
				.awardsItemInfo2 {
					align-items: center;
				}

				.awardsItemInfo1 {
					.awardName {
						flex: 0 0 45%;
						padding-right: var(--tab-right);
						margin-bottom: 0;
					}

					p {
						flex: 1 1 auto;
						padding-left: var(--tab-left);
						padding-right: var(--tab-right);
					}
				}

				.awardsItemInfo2 {
					flex: 1 1 auto;
					justify-content: flex-start;
				}

				.awardsYear {
					width: var(--sp14x);
					padding-left: var(--tab-left);
					padding-right: var(--tab-right);
				}

				.awardsLink {
					flex: 0 0 auto;
					padding-left: var(--tab-left);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			padding-top: var(--sp6x);
			padding-bottom: var(--sp6x);
			--tab-left: var(--sp4x);
			--tab-right: var(--sp4x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			.awardsItemWrapIcon {
				width: var(--sp13x);

				svg {
					width: var(--sp9x);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			.awardsItemCont {
				.awardsYear {
					width: var(--sp20x);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			padding-top: var(--sp6x);
			padding-bottom: var(--sp6x);
			--tab-left: var(--sp4x);
			--tab-right: var(--sp4x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			padding-top: var(--sp6x);
			padding-bottom: var(--sp6x);
			--tab-left: var(--sp5x);
			--tab-right: var(--sp5x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			padding-top: var(--sp7x);
			padding-bottom: var(--sp7x);
			--tab-left: var(--sp5x);
			--tab-right: var(--sp5x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			.awardsItemWrapIcon {
				svg {
					width: var(--sp11x);
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			padding-top: var(--sp8x);
			padding-bottom: var(--sp8x);
			--tab-left: var(--sp7x);
			--tab-right: var(--sp7x);

			.awardsItemWrapIcon {
				svg {
					width: var(--sp8x);
				}
			}

			.awardsItemCont {
				.awardsYear {
					width: var(--sp25x);
				}
			}
		}
	}

	.containerWrap {
		span {
			display: block;
		}

		.title {
			text-align: right;
		}
	}

	.contactTop {
		--wardsMarg: var(--sp7x);

		span {
			display: block;
		}

		.title {
			text-align: left;
		}

		.item-1 {
			font-weight: var(--ffLight);
		}

		.item-2 {
			font-weight: var(--ffLight);
			margin-left: var(--wardsMarg);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			--wardsMarg: var(--sp16x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			--wardsMarg: var(--sp19x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			--wardsMarg: var(--sp22x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			--wardsMarg: var(--sp29x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			--wardsMarg: var(--sp36x);
		}
	}

	.svg-max {
		width: 100%;
		height: 100%;
	}

	.svg-bland {
		width: 100%;
		height: 100%;
		fill: var(--color1);
	}

	::-webkit-scrollbar {
		width: 0px;
	}

	.forma .form-rows {
		opacity: 0;
	}

	.lengWrap {
		display: flex;

		.lengItem {
			position: relative;

			.hideText {
				opacity: 0;
				visibility: hidden;
			}

			.showText {
				position: absolute;
				top: 0;
				left: 0;
				text-align: center;

				&:hover {
					font-style: var(--ffItalic);
				}
			}

			&::after {
				content: '-';
				margin-left: var(--sp1x);
				margin-right: var(--sp1x);
			}

			&:last-child {
				&::after {
					content: none;
				}
			}
		}
	}

	//! Error page styles
	.error-page {
		display: flex;
		align-items: center;
		justify-content: center;

		.error-cont {
			--errColPaddingSize: var(--sp3x);

			.error-inner-cont {
				min-width: 100%;
				display: flex;
				flex-wrap: wrap;
				margin: 0 calc(-1 * var(--errColPaddingSize));

				.part {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					padding: 0 var(--errColPaddingSize);

					&.err-left {
						.arrow-btn {
							display: inline-flex;

							.word {
								text-align: left;

								.letter:nth-child(1) {
									box-shadow: none;
								}
							}
						}
					}
				}
			}

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
				.arrow-btn {
					margin-top: var(--sp15x);
				}
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
				height: 100%;
				display: flex;
				align-items: center;

				.error-inner-cont {
					.part {
						width: 50%;
					}
				}
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
				--errColPaddingSize: var(--sp6x);
			}
		}
	}

	.loading {
		animation: opacity 1s infinite;
		pointer-events: none !important;
	}

	@keyframes opacity {
		0% {
			opacity: 0.5;
		}

		50% {
			opacity: 0.7;
		}

		100% {
			opacity: 0.5;
		}
	}

	#nprogress {
		.bar {
			background: var(--color1) !important;
			mix-blend-mode: difference;
		}

		.peg {
			box-shadow: none !important;
		}
	}

	//! Custom fade styles
	.custom-fade-enter {
		opacity: 0;
	}

	.custom-fade-enter-active {
		opacity: 1;
		transition: opacity 400ms;
	}

	.custom-fade-exit {
		opacity: 1;
	}

	.custom-fade-exit-active {
		opacity: 0;
		transition: opacity 700ms;
	}
	}
	
`}`;

export default Typography;
