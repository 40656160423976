import { createGlobalStyle, css } from 'styled-components';

//! Fonts
import { fontAnonymousPro, fontPoppins, neueMetanaRegular } from '@utils/fonts';

//! Space generator
function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 250; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

const Variables = createGlobalStyle`${css`
	:root {
		//! Spacings
		--spSize: 8px;

		--sp0-5x: calc(var(--spSize) * 0.5);
		--sp0-75x: calc(var(--spSize) * 0.75);
		--sp1-5x: calc(var(--spSize) * 1.5);
		--sp2-5x: calc(var(--spSize) * 2.5);
		--sp3-5x: calc(var(--spSize) * 3.5);
		--sp7-5x: calc(var(--spSize) * 7.5);
		${spaceGenerator()};

		//! Colors
		--white: ${(props) => props.theme.colors.white};
		--black: ${(props) => props.theme.colors.black};
		--color1: var(--white);
		--color2: var(--black);
		--errorColor: ${(props) => props.theme.colors.red};

		//! Transition time size
		--trTime: 0.3s;

		//! Screen sizes
		--desktopSizeXL: ${(props) => props.theme.mediaQuery.DesktopSizeXL};
		--desktopSizeL: ${(props) => props.theme.mediaQuery.DesktopSizeL};
		--desktopSizeM: ${(props) => props.theme.mediaQuery.DesktopSizeM};
		--desktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
		--desktopSizeXS: ${(props) => props.theme.mediaQuery.DesktopSizeXS};
		--tabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
		--tabletSizeS: ${(props) => props.theme.mediaQuery.TabletSizeS};
		--mobileSize: ${(props) => props.theme.mediaQuery.MobileSize};

		//! Additional sizes
		--borderSize: 1px;
		--autoSelectBg: var(--color1);
		--autoSelectColor: var(--color2);

		//! Font family's
		--fontFamily1: ${fontAnonymousPro.style.fontFamily};
		--fontFamily2: ${fontPoppins.style.fontFamily};
		--fontFamily3: ${neueMetanaRegular.style.fontFamily};

		//! Font weights
		--ffLight: 300;
		--ffRegular: 400;
		--ffMedium: 500;
		--ffSemiBold: 600;
		--ffBold: 700;

		//! Font style
		--ffItalic: italic;

		//! New line height sizes
		--lineHeightH1: 0.87;
		--lineHeightH2: 0.87;
		--lineHeightH3: 0.87;
		--lineHeightH4: 1;
		--lineHeightH5: 1.5;
		--lineHeightH6: 1.5;
		--lineHeightP: 1.7;
		--lineHeightForm: 1;
		--lineHeightFormTitle: 1.2;
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
		:root {
			//! Font Sizes
			--h1: 256px;
			--h2: 200px;
			--h3: 160px;
			--h4: 136px;
			--h5: 48px;
			--h6: 32px;
			--h7: 40px;

			--pL: 72px;
			--pM: 24px;
			--p: 20px;
			--formSize: 104px;

			//! Container sizes
			--containerPaddingLR: var(--sp37x);

			//! Portfolio item margin size
			--portfolioItemMarg: var(--sp11x);

			//! Header height size
			--headerHeight: 200px;

			//! Sections distance
			--sectionToSection: var(--sp27x);

			//! Title to text distance
			--titleToText: var(--sp9x);

			//! Text to description distance
			--textToDescription: var(--sp6x);

			//! Description to btn distance
			--descToBtn: var(--sp8x);

			//! Title to content distance
			--titleToContent: var(--sp13x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		:root {
			//! Font Sizes
			--h1: 208px;
			--h2: 168px;
			--h3: 136px;
			--h4: 104px;
			--h5: 40px;
			--h6: 28px;
			--h7: 36px;

			--pL: 64px;
			--pM: 20px;
			--p: 16px;
			--formSize: 80px;

			//! Container sizes
			--containerPaddingLR: var(--sp18x);

			//! Portfolio item margin size
			--portfolioItemMarg: var(--sp8x);

			//! Header height size
			--headerHeight: 140px;

			//! Sections distance
			--sectionToSection: var(--sp22x);

			//! Title to text distance
			--titleToText: var(--sp8x);

			//! Text to description distance
			--textToDescription: var(--sp5x);

			//! Description to btn distance
			--descToBtn: var(--sp7x);

			//! Title to content distance
			--titleToContent: var(--sp12x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		:root {
			//! Font Sizes
			--h1: 160px;
			--h2: 120px;
			--h3: 104px;
			--h4: 88px;
			--h5: 32px;
			--h6: 24px;
			--h7: 28px;

			--pL: 56px;
			--pM: 18px;
			--p: 14px;
			--formSize: 64px;

			//! Container sizes
			--containerPaddingLR: var(--sp10x);

			//! Portfolio item margin size
			--portfolioItemMarg: var(--sp7x);

			//! Header height size
			--headerHeight: 106px;

			//! Sections distance
			--sectionToSection: var(--sp20x);

			//! Title to text distance
			--titleToText: var(--sp6x);

			//! Text to description distance
			--textToDescription: var(--sp4x);

			//! Description to btn distance
			--descToBtn: var(--sp6x);

			//! Title to content distance
			--titleToContent: var(--sp11x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		:root {
			//! Font Sizes
			--h1: 128px;
			--h2: 104px;
			--h3: 96px;
			--h4: 80px;
			--h5: 24px;
			--h6: 20px;
			--h7: 24px;

			--pL: 48px;
			--pM: 18px;
			--p: 14px;
			--formSize: 56px;

			//! Container sizes
			--containerPaddingLR: var(--sp10x);

			//! Portfolio item margin size
			--portfolioItemMarg: var(--sp5x);

			//! Header height size
			--headerHeight: 106px;

			//! Sections distance
			--sectionToSection: var(--sp19x);

			//! Title to text distance
			--titleToText: var(--sp5x);

			//! Text to description distance
			--textToDescription: var(--sp4x);

			//! Description to btn distance
			--descToBtn: var(--sp6x);

			//! Title to content distance
			--titleToContent: var(--sp11x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		:root {
			//! Font Sizes
			--h1: 128px;
			--h2: 104px;
			--h3: 96px;
			--h4: 80px;
			--h5: 24px;
			--h6: 20px;
			--h7: 24px;

			--pL: 40px;
			--pM: 18px;
			--p: 14px;
			--formSize: 56px;

			//! Container sizes
			--containerPaddingLR: var(--sp10x);

			//! Portfolio item margin size
			--portfolioItemMarg: var(--sp4x);

			//! Header height size
			--headerHeight: 106px;

			//! Sections distance
			--sectionToSection: var(--sp18x);

			//! Title to text distance
			--titleToText: var(--sp5x);

			//! Text to description distance
			--textToDescription: var(--sp4x);

			//! Description to btn distance
			--descToBtn: var(--sp5x);

			//! Title to content distance
			--titleToContent: var(--sp10x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		:root {
			--h1: 104px;
			--h2: 88px;
			--h3: 56px;
			--h4: 56px;
			--h5: 20px;
			--h6: 18px;
			--h7: 22px;

			--pL: 32px;
			--pM: 16px;
			--p: 14px;
			--formSize: 40px;

			//! Container sizes
			--containerPaddingLR: var(--sp8x);

			//! Portfolio item margin size
			--portfolioItemMarg: var(--sp4x);

			//! Header height size
			--headerHeight: 100px;

			//! Sections distance
			--sectionToSection: var(--sp16x);

			//! Title to text distance
			--titleToText: var(--sp4x);

			//! Text to description distance
			--textToDescription: var(--sp3x);

			//! Description to btn distance
			--descToBtn: var(--sp5x);

			//! Title to content distance
			--titleToContent: var(--sp9x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		:root {
			--h1: 104px;
			--h2: 88px;
			--h3: 56px;
			--h4: 56px;
			--h5: 20px;
			--h6: 18px;
			--h7: 22px;

			--pL: 32px;
			--pM: 16px;
			--p: 14px;
			--formSize: 40px;

			//! Container sizes
			--containerPaddingLR: var(--sp6x);

			//! Portfolio item margin size
			--portfolioItemMarg: var(--sp4x);

			//! Header height size
			--headerHeight: 100px;

			//! Sections distance
			--sectionToSection: var(--sp16x);

			//! Title to text distance
			--titleToText: var(--sp4x);

			//! Text to description distance
			--textToDescription: var(--sp3x);

			//! Description to btn distance
			--descToBtn: var(--sp4x);

			//! Title to content distance
			--titleToContent: var(--sp8x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		:root {
			--h1: 56px;
			--h2: 48px;
			--h3: 40px;
			--h4: 48px;
			--h5: 20px;
			--h6: 18px;
			--h7: 20px;

			--pL: 24px;
			--pM: 14px;
			--p: 14px;
			--formSize: 32px;

			//! New line height sizes
			--lineHeightH1: 1;
			--lineHeightH2: 1;
			--lineHeightH3: 1;

			//! Container sizes
			--containerPaddingLR: var(--sp2x);

			//! Portfolio item margin size
			--portfolioItemMarg: var(--sp4x);

			//! Header height size
			--headerHeight: 100px;

			//! Sections distance
			--sectionToSection: var(--sp15x);

			//! Title to text distance
			--titleToText: var(--sp3-5x);

			//! Text to description distance
			--textToDescription: var(--sp3x);

			//! Description to btn distance
			--descToBtn: var(--sp3x);

			//! Title to content distance
			--titleToContent: var(--sp5x);
		}
	}
`}`;

export default Variables;
